<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
        <path
            fill-rule="nonzero"
            d="M5.72727965.17432811v1.99969797l4.69165345-.0007071L.00961422 12.5826379l1.41421356 1.4142136L11.8331467 3.58753254l-.0007071 4.69165349h1.999698L13.8328447.173621z"
        />
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
